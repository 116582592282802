import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { values, difference } from 'underscore';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';

import t from 'react-translate';
import { SubmissionTab } from 'redux/schemas/models/video-practice';
import { authorRatingsSorted } from 'redux/selectors/skills-feedback';
import { getSubmission } from 'redux/selectors/video-practice';
import { NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import { tripleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { getSummary } from 'redux/actions/skills-feedback';
import { useAppDispatch } from 'redux/store';
import { makeQueryString } from 'shared/hooks/use-query';
import { setPracticeRoomState } from 'redux/actions/video-practice';
import { config } from '../../../../../config/pendo.config.json';
import { ActionTypes, PracticeSubmissionContext, Tab, selectedViewToIndex } from '../utils';

const styles = css`
  .tab {
    height: auto !important;
    min-height: ${tripleSpacing}px;
    padding-top: ${halfSpacing}px;
    padding-bottom: ${halfSpacing}px;
  }
`;

const PracticeSubmissionTabs = () => {
  const [{
    submissionId,
    isPracticeFeedback,
    isPracticeRoom,
    isCourseAdmin,
    selectedView,
    showCommentButton,
    isAdmin,
    isMyPractice,
    skillTags,
  }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const {
    isRatedByCurrentUser,
    isViewerMentor,
    hasInsightsEnabled,
  } = useSelector((state) => getSubmission(state, submissionId));
  const dispatch = useAppDispatch();
  const { summary, summaryOnceLoaded } = useSelector((state) => state.app.videoPracticeSubmissions[submissionId]) ?? {};
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const authorRatings = useSelector(state => authorRatingsSorted(state, submissionId, 'videoPracticeSubmissions'));
  const history = useHistory();

  const {
    submissionId: selectedSubmissionId,
    selectedSubmissionTab,
    selectedTab,
    scenarioId,
  } = useSelector((state) => state.app.practiceRoom.params);

  const onSelectTab = useCallback((tab: SubmissionTab) => {
    practiceSubmissionDispatch({
      type: ActionTypes.SET_SELECTED_VIEW,
      payload: tab,
    });
  }, [practiceSubmissionDispatch]);

  useEffect(() => {
    /**
     * Reset the URL and practice room state to base
     * ie., the URL don't need additional tab params once it is set inside the context
     * and state don't need to store the submission tab
     */
    if (selectedView && selectedView === selectedSubmissionTab) {
      if (selectedTab) {
        /**
         * After setting slected view it takes a while to update the tab index,
         * and restting these state before updating the tab index will cause the ui not to switch to tabs
         * so adding a timeout so that UI ` can perform the switch
         */
        setTimeout(() => {
          dispatch(setPracticeRoomState({
            scenarioId,
            selectedTab,
            submissionId,
            selectedSubmissionTab: null,
          }));
          history.push(makeQueryString({
            selected: selectedTab,
          }));
        });
      }
    }
  }, [selectedView, selectedSubmissionTab]);

  useEffect(() => {
    if (submissionId === selectedSubmissionId && selectedSubmissionTab && selectedView !== selectedSubmissionTab) {
      onSelectTab(selectedSubmissionTab);
    }
  }, [onSelectTab, selectedSubmissionId, selectedSubmissionTab, submissionId]);

  useEffect(() => {
    dispatch(getSummary({
      institutionId: currentInstitutionId,
      ownerId: submissionId,
      ownerType: 'VideoPracticeSubmission',
    }));
  }, [submissionId]);

  const tabs: Tab[] = [
    {
      text: t.PRACTICE_ROOM.SUBMISSION.TABS.COMMENTS(),
      onClick: () => onSelectTab(SubmissionTab.COMMENTS),
      pendoTagName: config.pendo.skillsFeedback.practiceTabComments,
      tabValue: SubmissionTab.COMMENTS,
    },
  ];

  const showYourSkillsFeedbackTab = useMemo(() => {
    const isCommonConditionMet = isRatedByCurrentUser || isCourseAdmin || isViewerMentor;
    if (!isMyPractice && skillTags?.length > 0) {
      if (isPracticeRoom && (authorRatings?.length > 0 || isCommonConditionMet)) {
        return true;
      }
      if (!isPracticeRoom && isCommonConditionMet) {
        return true;
      }
    }
    return false;
  }, [authorRatings?.length, isViewerMentor, isCourseAdmin, isMyPractice, isPracticeRoom, isRatedByCurrentUser]);

  const showAllSkillsFeedbackTab = useMemo(() => {
    if (summaryOnceLoaded) {
      return summary.some(({ totalRatings }) => totalRatings > 0);
    }
    return false;
  }, [summary, summaryOnceLoaded]);

  if (!isPracticeFeedback) {
    if (showYourSkillsFeedbackTab) {
      tabs.push({
        text: t.PRACTICE_ROOM.SUBMISSION.TABS.YOUR_SKILLS_FEEDBACK(),
        onClick: () => onSelectTab(SubmissionTab.AUTHOR_FEEDBACK),
        pendoTagName: config.pendo.skillsFeedback.practiceTabYourSkillsFeedback,
        tabValue: SubmissionTab.AUTHOR_FEEDBACK,
      });
    }
    if (isMyPractice || isCourseAdmin || isViewerMentor) {
      if (skillTags?.length > 0 && showAllSkillsFeedbackTab) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.ALL_SKILLS_FEEDBACK(),
          onClick: () => onSelectTab(SubmissionTab.ALL_FEEDBACK),
          pendoTagName: config.pendo.skillsFeedback.practiceTabAllSkillsFeedback,
          tabValue: SubmissionTab.ALL_FEEDBACK,
        });
      }

      if (hasInsightsEnabled) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.INSIGHTS(),
          onClick: () => onSelectTab(SubmissionTab.INSIGHTS),
          dataQA: 'automated-feedback-insights-tab',
          pendoTagName: config.pendo.practice.insightsTab,
          tabValue: SubmissionTab.INSIGHTS,
        });
      }
    }
  }

  const getActiveTabIndex = useCallback(() => {
    const allTabs = values(SubmissionTab);
    const availableTabs = tabs.map(tab => tab.tabValue);
    const missingTabs = difference(allTabs, availableTabs);
    const tabsBeforeActiveTab = missingTabs.filter(
      tab => selectedViewToIndex(tab) < selectedViewToIndex(selectedView),
    );
    return selectedViewToIndex(selectedView) - tabsBeforeActiveTab.length;
  }, [selectedView]);

  const showTabs = (showCommentButton || isAdmin) && tabs.length > 1;
  return (
    <div
      className='border-bottom border-gray-6 mb-4'
      css={styles}
    >
      {showTabs && tabs.length > 1 && (
        <NvResponsiveTabsRow
          defaultTabs={tabs}
          tabTextClass='card-title'
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          revertActiveTab={getActiveTabIndex()}
        />
      )}
    </div>
  );
};

export default PracticeSubmissionTabs;
